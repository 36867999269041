<template>
  <div class="md-app-container login-page">
<!--    <img src="../../assets/logo1.png" alt="logo">-->
    <md-card>
      <div>
        <img src="../../assets/images/logoTDD.png" alt="" class="logo">
      </div>
      <div class="md-ripple">
        <md-card-header>
          <div class="md-title"><b>{{ $t('login') }}</b></div>
          <div class="md-subhead">{{ $t('signIn') }}</div>
        </md-card-header>

        <md-card-content>
          <img class="name" src="../../assets/images/log.png" alt="Name">
          <md-field md-inline>
            <md-input v-model="name"  :placeholder="$t('name') "></md-input>
          </md-field>
          <img class="password" src="../../assets/images/pass.png" alt="Password">
          <md-field md-inline>
            <md-input v-model="password" type="password" :placeholder="$t('password') "></md-input>
          </md-field>

        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised" v-on:click="loginHandler">{{ $t('login') }}</md-button>
          <router-link to="/forgot-password">
            <md-button>{{ $t('forgot') }}</md-button>
          </router-link>
          <div style="position:absolute; bottom: 115px">
            <Messages :messages="messages"></Messages>
            <Errors :errors="errors"></Errors>
          </div>
        </md-card-actions>

      </div>
    </md-card>
    <p class="footer-text">{{ $t('useRight') }}</p>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"
import {mapActions} from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    name: '',
    password: ''
  }),
  mixins: [
    printArray,
    errors,
    messages,
  ],
  components: {
    Errors,
    Messages
  },
  methods: {
    ...mapActions(['login']),
    async loginHandler() {
      this.clearErrors()
      this.clearMessages()


     const data = await this.login({
        name: this.name,
        password: this.password
      })

      if(data.errors.length) {
        this.errors = data.errors[0];
        console.log(this.errors)
        this.setErrors([this.errors]);
      } else {
        this.$router.push({name: 'requests-list'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.login-page {
  background: #054F63;
  height: 100vh;
}

.logo{
  margin: 45px 0 18px 70px;
}

.md-subhead{
  margin-top: 11px;
}

.md-app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 120px;
}

.md-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 32px;
  color: black;
}

.md-ripple {
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 0;
}

.md-card.md-theme-default {
  width: 496px;
  margin-top: 37px;
}

.md-field{
  padding: 0;
}

.md-field .md-input, .md-field .md-textarea {
  border: 1px solid #CDD0D9;
  box-sizing: border-box;
  border-radius: 6px;
  height: 60px;
  padding-left: 15%;
}

.md-field.md-theme-default:after {
  background-color: unset;
}

.md-button.md-theme-default.md-raised {
  background: #EC8B31 !important;
  border-radius: 4.68227px;
  color: white !important;
}

.md-card-actions.md-alignment-right {
  justify-content: space-between !important;
  margin-bottom: 122px;
}

.md-field:last-child {
  margin-bottom: 40px;
}

.name {
  position: absolute;
  top: 133px;
  left: 16%;
}

.password {
  position: absolute;
  top: 216px;
  left: 16%;
}

.md-card-content{
  padding: 7px!important;
  margin-bottom: -25px;
  label:nth-child(1){
    position: absolute;
    left: 18%;
    top: 35%;
  }
}

.footer-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width: 55%;
  text-align: center;
  margin-top: 95px;
}
</style>
